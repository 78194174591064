import { combineReducers } from "redux";
import { userInfoApi, storeApi, dashboardApi, userStoreApi, deviceTypesApi, deviceTypeDetailsApi } from 'api';

import userReducer, { UserInfo } from "./slices/user";
import languageReducer, { LanguageInfo } from "./slices/language";

export interface RootState {
  user: UserInfo;
  language: LanguageInfo;
};

const rootReducer = combineReducers({
  user: userReducer,
  language: languageReducer,
  [userInfoApi.reducerPath]: userInfoApi.reducer,
  [storeApi.reducerPath]: storeApi.reducer,
  [deviceTypesApi.reducerPath]: deviceTypesApi.reducer,
  [deviceTypeDetailsApi.reducerPath]: deviceTypeDetailsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [userStoreApi.reducerPath]: userStoreApi.reducer,
});

export default rootReducer;