import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  userInfoApi,
  storeApi,
  deviceTypesApi,
  deviceTypeDetailsApi,
  dashboardApi,
  userStoreApi

} from 'api';

import rootReducer from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userInfoApi.middleware)
      .concat(storeApi.middleware)
      .concat(deviceTypesApi.middleware)
      .concat(deviceTypeDetailsApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(userStoreApi.middleware),
});

setupListeners(store.dispatch);