import { FC, useEffect, useRef, useState } from "react";
import {
  styled,
  alpha,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Input,
  TextField,
  Button,
  withWidth,
} from "@material-ui/core";

import { DeleteForever, Edit } from "@material-ui/icons";
import useFormattedMessage from "translations/useFormattedMessage";
import {
  useAddSendingMutation,
  ISending,
} from "api/dashboardApi";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useGetLastReadingsQuery } from "api/dashboardApi";

interface DataSendCellProps {
  data: {
    paramName: string;
  };
  onEdit?: () => void;
  onDelete?: () => void;
}

const UserSection = styled('div')(({ theme }) => {
  const border = `1px solid ${alpha(theme.palette.primary.main, .5)}`
  return {
    padding: theme.spacing(1),
    borderTop: border,    
    display:"table-row",
    "&:last-child": {
      borderBottom: border,
    }
  }
});


const DataSendCell: FC<DataSendCellProps> = ({
  data,
  onEdit = () => {},
  onDelete = () => {},
}) => {

  const newValueRef = useRef<any | null>(null);
  const { idStore } = useParams();
  const t = useFormattedMessage({ prefix: 'reading.' });
  const theme = useTheme();
  //const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [ AddSending ] = useAddSendingMutation();
  const { data: dataLastReadingByIdStore  = [], isLoading: isLoadingLastReadingByIdStore, refetch: refetchLastReadingByIdStore  } = useGetLastReadingsQuery( Number(idStore), { refetchOnMountOrArgChange: true });
  
  const LastReadingByIdStore = dataLastReadingByIdStore ?? [];
  var [ StateLastReading, setStateLastReadingByIdStore ] = useState({
    LastReadings: LastReadingByIdStore
  });
  var [ stateToExecAfterLoading, setStateToExecAfterLoading ] = useState(true);
  
  
  const updateStateLastReadingByIdStore = (p: any) => setStateLastReadingByIdStore((StateLastReading: any) => {
    return {
      LastReadings:p,
    }
  });
  if (!isLoadingLastReadingByIdStore && stateToExecAfterLoading)
  {
    const ReadingByIdStore = dataLastReadingByIdStore ?? [];
    updateStateLastReadingByIdStore(ReadingByIdStore);
    setStateToExecAfterLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setStateToExecAfterLoading(true);
      refetchLastReadingByIdStore();
      if (!isLoadingLastReadingByIdStore && stateToExecAfterLoading)
      {
        const ReadingByIdStore = dataLastReadingByIdStore ?? [];
        updateStateLastReadingByIdStore(ReadingByIdStore);
        setStateToExecAfterLoading(false);
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [StateLastReading]);

  async function sendNewValue () {
    console.log(newValueRef.current.value);
    await toast.promise(
      AddSending({body: {
        idStore: Number(idStore),
        dataName: data.paramName,
        dataValue: Number(newValueRef.current.value)
      } as ISending }).unwrap(),
    {
      success: `${t("deviceAdded")}`,
      loading: `${t("loading")}`,
      error: `${t("addDeviceError")}` ,
    });
  }
  return (
    <UserSection>
        <Box
          display="table-cell"
          style={{verticalAlign:"middle"}}
        >
          <Typography
            variant="subtitle2"
          >
            {data.paramName}
          </Typography>
        </Box>
        <Box
          display="table-cell"
          style={{verticalAlign:"middle", width:"80px"}}
        >
          <Typography
          variant="subtitle2"
          >
            {StateLastReading.LastReadings.find((s) => s.dataName == data.paramName)?.dataValue.toString() ?? '-'}
          </Typography>
        </Box>
        <Box
          display="table-cell"
          style={{verticalAlign:"middle"}}
        >
          <TextField
          variant="filled"
          type={'number'}
          autoComplete="off"
          style={{width:"80px"}}
          inputRef={newValueRef}
        />
        </Box>
        <Box
          display="table-cell"
          style={{verticalAlign:"middle"}}
        >
          <Button
            color="primary" variant="contained"
            onClick={async () => sendNewValue()}
          >
            {t("SendData")}
          </Button>
        </Box>
    </UserSection>
  );

};

export default DataSendCell;
