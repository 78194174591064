import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#14637D',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#3F3047',
      dark: '#ba000d',
      contrastText: '#0F0',
    },
  },
});

export default theme;