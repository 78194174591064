import { IntlProvider } from "react-intl";

import { Langs } from "./constants";

import englishText from "../assets/translations/english.json";
import italianText from "../assets/translations/italian.json";
// import Text from "../assets/translations/romanian.json";
import useLanguage from "../hooks/useLanguage";

interface TranslationProps {
  children: JSX.Element;
}

const getLanguage = (lang: Langs) => {
  return {
    [Langs.EN]: englishText,
    [Langs.IT]: italianText,
  }[lang];
}

export const Translation = ({ children }: TranslationProps): JSX.Element => {
  const { language } = useLanguage();
  return (
    <IntlProvider
      messages={getLanguage(language)}
      locale="en"
      defaultLocale="en"
    >
      {children}
    </IntlProvider>);
};

export default Translation;