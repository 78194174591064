import { lazy } from "react";

import PrivateRoute from "./PrivateRoute";
import Layout from "../modules/layout";

const PATHS = {
  HOME: '',
  LOGIN: 'login',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  MANAGEMENT: 'management',
} as const;

const Home = lazy(() => import('../modules/home'));
const Login = lazy(() => import('../modules/login'));
const Profile = lazy(() => import('../modules/profile'));
const Settings = lazy(() => import('../modules/settings'));
const Management = lazy(() => import('../modules/management'));

const paths = [
  {
    key: 'Home',
    path: PATHS.HOME,
    element: (<PrivateRoute>
      <Layout>
        <Home />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Profile',
    path: PATHS.PROFILE,
    element: (<PrivateRoute>
      <Layout>
        <Profile />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Settings',
    path: PATHS.SETTINGS,
    element: (<PrivateRoute>
      <Layout>
        <Settings />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Management',
    path: `${PATHS.MANAGEMENT}/*`,
    element: (<PrivateRoute>
      <Layout>
        <Management />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Login',
    path: PATHS.LOGIN,
    element: <Login />,
  },
  {
    key: 'NotFound',
    path: '*',
    element: <p> NOT </p>,
  }
];

export default paths;