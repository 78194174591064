import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'stores/';

export interface IStore {
  id: number;
  uuid: string;
  name: string;
  description: string;
  notes: string;
  storeNumber: string;
  deviceType: number;
  [key: string]: any;
}


const storeInfoApi = createApi({
  tagTypes: ['Stores', 'Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'storeReducer',
  endpoints: (build) => ({
    getAllStores: build.query<IStore[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['Stores']
    }),
    addStore: build.mutation<any, { body: Partial<IStore> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Stores','Dashboard'],
    }),
    updateStore: build.mutation<any, { body: Partial<IStore>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Stores', 'Dashboard'],
    }),
    deleteStore: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: ['Stores','Dashboard'],
    })
  }),
});

export default storeInfoApi;

export const {
  useGetAllStoresQuery,
  useAddStoreMutation,
  useUpdateStoreMutation,
  useDeleteStoreMutation,
} = storeInfoApi;