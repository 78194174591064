import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'dashboard/';

export interface IStore {
  id: number;
  uuid: string;
  name: string;
  address: string;
  deviceType: number;
}

export interface IDevice {
  id: number;
  uuid: string;
  name: string;
  macAddress: string;
}

export interface IReadings {
  id: number;
  idStore: number;
  dataName: string;
  dataValue: number;
  readingAt: Date;
}

export interface IDeviceTypeDetail {
  id: number;
  idDeviceType: number;
  dataField: string;
  dataName: string;
  dataNameEng: string;
}

export interface ISending {
  id: number;
  idStore: number;
  dataName: string;
  dataValue: number;
  sendingAt: Date;
  executed: boolean;
  executedAt: Date;
}

const dashboardApi = createApi({
  tagTypes: ['Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'dashboardReducer',
  endpoints: (build) => ({
    getLastReadings: build.query<IReadings[], number>({
      query: (idStore) => routeRoot.concat(`readingLast/${idStore}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getStore: build.query<IStore, number>({
      query: (storeId) => routeRoot.concat(`store/${storeId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getDevice: build.query<IDevice, number>({
      query: (deviceId) => routeRoot.concat(`device/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getDeviceTypeDetailByIdDeviceType: build.query<IDeviceTypeDetail[], number>({
      query: (idStore) => routeRoot.concat(`readingDeviceType/${idStore}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getAllReadingsByIdStore: build.query<IReadings[], number>({
      query: (idStore) => routeRoot.concat(`readingLast/${idStore}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getAllReadingsToExportByIdStore: build.query<IReadings[], number>({
      query: (idStore) => routeRoot.concat(`readingToExportByIdStore/${idStore}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getAllDeviceTypeDetailsToSendByIdStore: build.query<IDeviceTypeDetail[], number>({
      query: (idStore) => routeRoot.concat(`deviceTypeDetailsToSendByIdStore/${idStore}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    addSending: build.mutation<any, { body: Partial<ISending> }>({
      query: ({ body }) => ({
        url: routeRoot.concat(`Sending/`),
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Dashboard'],
    })
  }),
});

export default dashboardApi;

export const {
  useGetLastReadingsQuery,
  useGetStoreQuery,
  useGetDeviceQuery,
  useGetDeviceTypeDetailByIdDeviceTypeQuery,
  useGetAllReadingsByIdStoreQuery,
  useGetAllReadingsToExportByIdStoreQuery,
  useGetAllDeviceTypeDetailsToSendByIdStoreQuery,
  useAddSendingMutation
} = dashboardApi;