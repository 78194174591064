import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'users/';

interface IUserInfo {
  email: string;
  role: string;
  id?: string;
}

interface IAddUser {
  mfaEnabled: boolean;
  password: string;
}

const userInfoApi = createApi({
  tagTypes: ['Users'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'userReducer',
  endpoints: (build) => ({
    getUserInfo: build.query<IUserInfo, void>({
      query: () => routeRoot.concat('me'),
      keepUnusedDataFor: 60,
    }),
    getAllUsers: build.query<IUserInfo[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['Users']
    }),
    addUser: build.mutation<any, { body: IUserInfo & IAddUser }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Users'],
    }),
    updateUser: build.mutation<any, { body: IUserInfo & IAddUser }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id || ''),
        method: 'PATCH',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Users'],
    }),
    deleteUser: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    })
  }),
});

export default userInfoApi;

export const {
  useGetUserInfoQuery,
  useGetAllUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation
} = userInfoApi;